import { Typography } from "@mui/material";
import { useTaskContext } from "../hooks/useTaskContext";
import { useAuthContext } from '../hooks/useAuthContext';
import { useNavigate } from "react-router-dom";
import { useLogout } from '../hooks/useLogout';
import Button from '@mui/material/Button';
import { Box, Modal } from "@mui/material";
import TextField from '@mui/material/TextField';
import { useState } from "react"
import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import jsPDF from 'jspdf'
import 'jspdf-autotable';
import { useMemo } from "react"
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material'



const Completed = () => {

    const { task, dispatch } = useTaskContext()
    const { user } = useAuthContext()

    //State for selecting Job
    const [selectedID, setSelectedID] = useState("")

    //Create job
    const [openAddCustomJob, setOpenAddCustomJob] = React.useState(false);
    const handleOpenAddCustomJob = () => setOpenAddCustomJob(true);
    const handleCloseAddCustomJob = () => { setOpenAddCustomJob(false); setError(""); }

    //Delete Job
    const [openDeleteJob, setOpenDeleteJob] = React.useState(false);
    const handleOpenDeletejob = () => setOpenDeleteJob(true);
    const handleCloseDeleteJob = () => setOpenDeleteJob(false);

    //error Text
    const [error, setError] = useState(null)

    //States for Adding Job
    const [jobName, setjobName] = useState("")
    const [completedDate, setcompletedDate] = useState(null)
    const [replacementProductName, setreplacementProductName] = useState("")
    const [replacementProductLink, setreplacementProductLink] = useState("")
    const [completedVariableValue, setcompletedVariableValue] = useState("")
    const [completedJobDescription, setcompletedJobDescription] = useState("")

    const navigate = useNavigate()
    const { logout } = useLogout()

    useMemo(() => {

        if (!user) {
            return null
        }
        //console.log(task.completedJobs)

        task.completedJobs.sort((a, b) => {
            let dateA = new Date(a.completedDate)
            let dateB = new Date(b.completedDate)
            return dateB - dateA
        })
        //console.log("Sorted")

        //console.log(task.completedJobs)

    }, [task.completedJobs, user])

    function convertDate(date) {
        if (date != null) {
            let x = new Date(date)
            let stringDate = (x.getMonth() + 1).toString() + "/" + x.getDate().toString() + "/" + x.getFullYear().toString()
            return stringDate
        }
        return ""
    }

    const handleAddCustomJob = async (e) => {
        if (!user) {
            setError("Must Be Logged In")
            return null
        }

        //NEED TO CHECK IF REQUIRED INPUTS ARE EMPTY !!!!!
        if (!jobName || !completedDate || !completedVariableValue) {
            setError("Required fields have not been filled in")
            return null
        }

        const customJob = { completedJobs: { jobName, completedDate, completedVariableValue, replacementProductName, replacementProductLink, completedJobDescription } }

        //Need to Change fetch location
        const response = await fetch(process.env.REACT_APP_BACKENDURL + '/api/addcustomjob/' + task._id, {
            method: 'PATCH',
            body: JSON.stringify(customJob),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }

        })

        const json = await response.json()

        if (!response.ok) {
            setError(json.error)

            if (response.status === 404) {

                navigate("/404Error")
            }

            if (response.status === 401) {

                if (json.error === "Token Expired") {
                    logout()
                    navigate('/Login')
                }
                else {
                    navigate("/401Error")
                }
            }
        }
        if (response.ok) {

            //dispatch({ type: "ADD_JOB", payload: jobElements })
            handleCloseAddCustomJob()
            //console.log(task)
            //THis is not ideal at all but is only workaround at the moment. context doesnt have _id so remaping fails
            setError("")
            //Refresh
            navigate(0)

        }

    }

    const handleDeleteCustomJob = async (e) => {

        if (!user) {
            return null
        }

        //console.log(selectedID)

        const completdJobId = { _id: selectedID }

        const response = await fetch(process.env.REACT_APP_BACKENDURL + '/api/deletecustomjob/' + task._id, {
            method: "PATCH",
            body: JSON.stringify(completdJobId),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })

        const json = await response.json()

        if (!response.ok) {
            // setError(json.error)

            if (response.status === 404) {

                navigate("/404Error")
            }

            if (response.status === 401) {

                if (json.error === "Token Expired") {
                    logout()
                    navigate('/Login')
                }
                else {
                    navigate("/401Error")
                }
            }
        }
        if (response.ok) {
            //THis is not ideal at all but is only workaround at the moment. context doesnt have _id so remaping fails
            //Refresh
            navigate(0)

        }

    }

    function Row(props) {

        const { completedJob } = props

        const [open, setOpen] = React.useState(false);

        let rpn = <></>
        let rpl = <></>
        let jb = <></>

        let IconButtonVariable = <>                            
            <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
            >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
        </>

        if (completedJob.replacementProductName !== "") {
            rpn = <><Typography variant="h5">Replacement Product Name:</Typography>
                <Typography variant="h6">{completedJob.replacementProductName}</Typography></>
        }

        if (completedJob.replacementProductLink !== "") {
            rpl = <><Typography variant="h5">Replacement Product Link:</Typography>
                <Typography>{completedJob.replacementProductLink}</Typography></>
        }

        if (completedJob.completedJobDescription !== "") {
            jb = <><Typography variant="h5" >Job Description:</Typography>
                <Typography >{completedJob.completedJobDescription}</Typography></>
        }

        if (completedJob.replacementProductName === "" && completedJob.replacementProductLink === "" && completedJob.completedJobDescription === "") {
            IconButtonVariable = <></>
        }

        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell>
                        {IconButtonVariable}
                    </TableCell>

                    <TableCell component="th" scope="row">
                        {completedJob.jobName}
                    </TableCell>
                    <TableCell >{convertDate(completedJob.completedDate)}</TableCell>
                    <TableCell >{completedJob.completedVariableValue}</TableCell>
                    <TableCell id={completedJob._id}> <IconButton id={completedJob._id} onClick={(e) => { setSelectedID(String(e.target.parentNode.id)); handleOpenDeletejob() }}><HighlightOffOutlinedIcon id={completedJob._id}></HighlightOffOutlinedIcon></IconButton></TableCell>

                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
                                    <Box sx={{ marginRight: 5, width: "500px" }}>
                                        {rpn}
                                    </Box>

                                    <Box sx={{ width: "500px" }}>
                                        {rpl}
                                    </Box>

                                </Box>
                                <Divider sx={{ marginTop: 5, marginBottom: 5 }} />
                                <Box sx={{ width: { xs: "300px", sm: "500px", md: "900px", lg: "1100px" } }}>
                                    {jb}
                                </Box>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    function createCompletedPDF() {

        if (!user) {
            return null
        }

        const doc = new jsPDF('landscape')

        doc.text(task.taskName + " Completed Jobs Report", 15, 10)
        doc.text("Current " + task.trackingVariableName + ": " + task.trackingVariableValue + "    Date: " + convertDate(task.lastUpdated), 180, 10)
        doc.text("Created Using:  WWW.DIYTASKTRACKER.COM", 160, 200)

        doc.autoTable({
            columns: [
                { header: 'Job Name', dataKey: 'jobName' },
                { header: 'Completed Date', dataKey: 'completedDate' },
                { header: task.trackingVariableName + ' Value', dataKey: 'completedVariableValue' },
                { header: 'Product Name', dataKey: 'replacementProductName' },
                { header: 'Product Link ' + task.trackingVariableName, dataKey: 'replacementProductLink' },
                { header: 'Description', dataKey: 'completedJobDescription' },
            ],

            body: task.completedJobs.map(({ jobName, completedDate, completedVariableValue, replacementProductName, replacementProductLink, completedJobDescription, }) => {
                return [jobName, convertDate(completedDate), completedVariableValue, replacementProductName, replacementProductLink, completedJobDescription]
            }),

            headStyles: {
                fillColor: [0, 255, 0],
                fontSize: 13,
            },

            columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 30 },
                2: { cellWidth: 20 },
                3: { cellWidth: 30 },
                4: { cellWidth: 30 },
            },

        })

        doc.save(task.taskName + " Completed PDF")

    }

    return (
        <>
            {/* this is the Delete job Modal */}
            <Modal
                open={openDeleteJob}
                onClose={handleCloseDeleteJob}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box id="" sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '70%', lg: '1000px' },
                    bgcolor: 'background.paper',
                    borderStyle: "solid",
                    borderColor: "Red",
                    borderWidth: 5,
                    borderRadius: '25px',
                    boxShadow: 24,
                    textAlign: 'center',
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h4" component="h2" >
                        Delete Job
                    </Typography>
                    <Typography variant="h5" sx={{ margin: 5 }}> Are you sure you want to delete custom job??</Typography>

                    <Box sx={{

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: 2,
                        marginTop: 4


                    }}>
                        <Button variant="outlined" size="medium" sx={{
                            borderRadius: 25,
                            border: 3,
                            ":hover": {
                                border: 3,
                            },
                            borderColor: "primary.dark",
                            color: "primary.light",
                            fontFamily: 'Allerta Stencil, sans-serif',
                        }}
                            onClick={handleCloseDeleteJob}
                        >Cancel</Button>

                        <Button variant="contained" size="large" sx={{
                            borderRadius: 25,
                            backgroundImage: 'linear-gradient(180deg, rgba(5, 0, 255, 1) 0%, rgba(0, 255, 56, 1) 100%)',
                            fontFamily: 'Allerta Stencil, sans-serif',
                            marginLeft: 17,
                        }}
                            onClick={handleDeleteCustomJob}
                        >Submit</Button>


                    </Box>

                </Box>

            </Modal >

            {/*THis is the Add Custom Job Modal for Tracker*/}
            <Modal
                open={openAddCustomJob}
                onClose={handleCloseAddCustomJob}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box id="" sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '70%', lg: '1000px' },
                    bgcolor: 'background.paper',
                    borderStyle: "solid",
                    borderColor: "#00ff38",
                    borderWidth: 5,
                    borderRadius: '25px',
                    boxShadow: 24,
                    textAlign: 'center',
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h4" component="h2" sx={{fontSize:{xs:'25px', lg:'35px'}}}>
                        Add Custom Job
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <TextField id="outlined-basic" label="Job Name" variant="outlined" size='small' sx={{
                            marginLeft: { xs: 1, lg: 20 },
                            marginRight: { xs: 1, lg: 20 },
                            marginTop: 1,
                            marginBottom: 1,

                        }}
                            value={jobName}
                            onChange={(e) => {
                                setjobName(e.target.value);
                            }}
                        />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker sx={{
                                marginLeft: { xs: 1, lg: 20 },
                                marginRight: { xs: 1, lg: 20 },
                                marginTop: 1,
                                marginBottom: 1

                            }}

                                value={completedDate}
                                onChange={(e) => {
                                    setcompletedDate(e)
                                }}
                                slotProps={{ textField: { size: 'small' } }}
                            ></DatePicker>
                        </LocalizationProvider>

                        <TextField id="outlined-basic" label={task.trackingVariableName + " When Job Was Completed"} variant="outlined" size='small'sx={{
                            marginLeft: { xs: 1, lg: 20 },
                            marginRight: { xs: 1, lg: 20 },
                            marginTop: 1,
                            marginBottom: 1,

                        }}
                            value={completedVariableValue}
                            onChange={(e) => {
                                setcompletedVariableValue(e.target.value);
                            }}
                        />

                        <TextField id="outlined-basic" label={"Product Name (Optional)"} variant="outlined" size='small'sx={{
                            marginLeft: { xs: 1, lg: 20 },
                            marginRight: { xs: 1, lg: 20 },
                            marginTop: 1,
                            marginBottom: 1,

                        }}
                            value={replacementProductName}
                            onChange={(e) => {
                                setreplacementProductName(e.target.value);
                            }}
                        />


                        <TextField id="outlined-basic" label="Product Link (Optional)" variant="outlined" size='small'sx={{
                            marginLeft: { xs: 1, lg: 20 },
                            marginRight: { xs: 1, lg: 20 },
                            marginTop: 1,
                            marginBottom: 1,

                        }}
                            value={replacementProductLink}
                            onChange={(e) => {
                                setreplacementProductLink(e.target.value);
                            }}
                        />

                        <TextField id="outlined-basic" label="Job Description (Optional)" variant="outlined" size='small'sx={{
                            marginLeft: { xs: 1, lg: 20 },
                            marginRight: { xs: 1, lg: 20 },
                            marginTop: 1,
                            marginBottom: 1,

                        }}
                            multiline
                            value={completedJobDescription}
                            onChange={(e) => {
                                setcompletedJobDescription(e.target.value);
                            }}
                        />

                        <Typography sx={{ color: 'red' }}>{error}</Typography>

                    </Box>
                    <Box sx={{

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: 0,
                        marginTop: 3


                    }}>
                        <Button variant="outlined" size="medium" sx={{
                            borderRadius: 25,
                            border: 3,
                            ":hover": {
                                border: 3,
                            },
                            borderColor: "primary.dark",
                            color: "primary.light",
                            fontFamily: 'Allerta Stencil, sans-serif',
                        }}
                            onClick={handleCloseAddCustomJob}
                        >Cancel</Button>

                        <Button variant="contained" size="large" sx={{
                            borderRadius: 25,
                            backgroundImage: 'linear-gradient(180deg, rgba(5, 0, 255, 1) 0%, rgba(0, 255, 56, 1) 100%)',
                            fontFamily: 'Allerta Stencil, sans-serif',
                            marginLeft: 17,
                        }}
                            onClick={handleAddCustomJob}
                        >Submit</Button>


                    </Box>

                </Box>

            </Modal >


            <Box sx={{ borderColor: "#00ff38", borderStyle: "solid", borderRadius: "25px", borderWidth: "5px", marginTop: '15px', marginLeft: '15px', marginRight: '15px', padding: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap", alignItems: 'flex-start' }}>

                    <Typography variant="h4" sx={{ textAlign: "center" }}>Completed Jobs</Typography>

                </Box>

                {/*THis is  the left column */}

                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        flexWrap: "wrap",
                        flexDirection: { md: 'column', xl: 'row' }
                    }}>

                    <Box sx={{
                        textAlign: "center",
                        margin: 2
                    }}>

                        <Box sx={{ display: 'flex', flexDirection: "column" }}>

                            <Button variant="outlined" size="meduim" sx={{
                                borderRadius: 25,
                                border: 3,
                                ":hover": {
                                    border: 3,
                                },
                                borderColor: "black",
                                color: "black",
                                fontFamily: 'Allerta Stencil, sans-serif',
                                margin: 2
                            }} onClick={createCompletedPDF}
                                style={{ width: 250 }}
                            >
                                Download PDF</Button>

                        </Box>
                    </Box>

                    {/*THis is the Table */}
                    <Box sx={{ display: 'flex', flexDirection: "column", width: { xs: '90%', xl: 1175 } }}>
                        <TableContainer component={Paper}>
                            <Table stickyHeader aria-label="collapsible table" style={{ overflow: "auto" }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>Job Name</TableCell>
                                        <TableCell >Completed Date</TableCell>
                                        <TableCell >Completed Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {task.completedJobs.map((completedJob) => (
                                        //console.log(completedJobs)
                                        <Row key={completedJob.jobName} completedJob={completedJob} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>

                    {/*THis is the right column */}
                    <Box sx={{ textAlign: "center", margin: 2 }}>

                        <Box sx={{ display: 'flex', flexDirection: "column" }}>

                            <Button variant="outlined" size="meduim" sx={{
                                borderRadius: 25,
                                border: 3,
                                ":hover": {
                                    border: 3,
                                },
                                borderColor: "Blue",
                                color: "Blue",
                                fontFamily: 'Allerta, sans-serif',
                                margin: 2
                            }}
                                onClick={handleOpenAddCustomJob}
                                style={{ width: 210 }}
                            >Add Completed Job</Button>
                        </Box>
                    </Box>

                </Box>

            </Box >

        </>
    )

}

export default Completed