import { Typography} from "@mui/material";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Dev = () => {
    return (
        <>
            <Navbar></Navbar>
            <div className="Home">
                <div className="Message">
                    <Typography variant="h2" sx={{ fontSize: { xs: '40px', sm: '50px', md: '70px' }, color: "primary.main" }}>Developer</Typography>
                    <Typography variant="h2" sx={{ fontSize: { xs: '40px', sm: '50px', md: '70px' }, color: "secondary.main" }}>Updates</Typography>                    
                </div>
                
            </div>
            <Typography component="div" sx={{display:"flex", fontSize:"1.5rem",margin:"15%", marginTop:"50px"}}>
                <ul>

                    <li>12/03/2023 - Dev Page Created</li>
                    <ul>
                        <li>Developer Page Created for tracking website updates</li>
                        <li>Updated Favicon and manifest</li>
                    </ul>
                    <br></br>
                    

                </ul>


            </Typography>

            <Footer></Footer>

        </>
    )
}

export default Dev;