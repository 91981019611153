import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Button from '@mui/material/Button';
import { Box, Modal, Typography } from "@mui/material";
import TextField from '@mui/material/TextField';
import { useState } from "react"
import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormLabel from '@mui/material/FormLabel';
import { useTaskContext } from "../hooks/useTaskContext";
import { useAuthContext } from '../hooks/useAuthContext';
import { useNavigate } from "react-router-dom";
import { useLogout } from '../hooks/useLogout';
import dayjs from 'dayjs';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material'
import jsPDF from 'jspdf'



const Tracker = () => {

    //Context
    const { task, dispatch } = useTaskContext()
    const { user } = useAuthContext()

    //Delete Job
    const [openDeleteJob, setOpenDeleteJob] = React.useState(false);
    const handleOpenDeletejob = () => setOpenDeleteJob(true);
    const handleCloseDeleteJob = () => setOpenDeleteJob(false);

    //Delete Tacker 
    const [openDeleteTracker, setOpenDeleteTracker] = React.useState(false);
    const handleOpenDeleteTracker = () => setOpenDeleteTracker(true);
    const handleCloseDeleteTracker = () => setOpenDeleteTracker(false);

    //Create job
    const [openAddJob, setOpenAddJob] = React.useState(false);
    const handleOpenAddJob = () => setOpenAddJob(true);
    const handleCloseAddJob = () => { setOpenAddJob(false); setError(""); }

    //Update Task
    const [openUpdateTask, setOpenUpdateTask] = React.useState(false);
    const handleOpenUpdateTask = () => setOpenUpdateTask(true);
    const handleCloseUpdateTask = () => { setOpenUpdateTask(false); setError(""); }

    const [newVariable, setNewVariable] = useState("")

    //error Text
    const [error, setError] = useState(null)

    //State for selecting Job
    const [selected, setSelected] = useState(null)

    //States for Adding Job
    const [jobName, setJobName] = useState("")
    const [lastPreformedDate, setLastPreformedDate] = useState(null)
    const [variableInterval, setVariableInterval] = useState("")
    const [monthTimeInterval, setMonthTimeInterval] = useState("")
    const [lastPreformedVariable, setLastPreformedVariable] = useState("")
    const [jobType, setJobType] = useState("")

    //States for Adding CustomJob
    // const [customJobName, setcustomJobName] = useState("")
    //const [completedDate, setcompletedDate] = useState(null)
    const [replacementProductName, setreplacementProductName] = useState("")
    const [replacementProductLink, setreplacementProductLink] = useState("")
    // const [completedVariableValue, setcompletedVariableValue] = useState("")
    const [completedJobDescription, setcompletedJobDescription] = useState("")

    //Create job
    const [openAddCustomJob, setOpenAddCustomJob] = React.useState(false);
    const handleOpenAddCustomJob = () => setOpenAddCustomJob(true);
    const handleCloseAddCustomJob = () => { setOpenAddCustomJob(false); setError(""); }

    const navigate = useNavigate()

    const { logout } = useLogout()

    //convert Date
    function convertDate(date) {
        if (date != null) {
            let x = new Date(date)
            let stringDate = (x.getMonth() + 1).toString() + "/" + x.getDate().toString() + "/" + x.getFullYear().toString()
            return stringDate
        }
        return ""
    }
    function convertBoolean(boolean) {

        if (boolean) {
            return "TRUE"
        }
        else {
            return "False"
        }
    }
    function handleSelect(e) {

        //console.log(e.target.parentNode.style.backgroundColor == '')

        if (selected == null && e.target.parentNode.style.backgroundColor === "") {
            e.target.parentNode.style.backgroundColor = 'yellow'
            setSelected(e.target.parentNode.id)

        }
        else if (selected != null && e.target.parentNode.style.backgroundColor === "yellow") {
            e.target.parentNode.style.backgroundColor = ''
            setSelected(null)

        }

        else if (selected != null && e.target.parentNode.style.backgroundColor === "") {
            document.getElementById(selected).style.backgroundColor = ''
            e.target.parentNode.style.backgroundColor = 'yellow'
            setSelected(e.target.parentNode.id)

        }


    }
    const handleDeleteTracker = async (e) => {

        if (!user) {
            setError("Must Be Logged In")
            return null
        }

        const response = await fetch(process.env.REACT_APP_BACKENDURL + '/api/tasks/' + task._id, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${user.token}`
            }

        })

        const json = await response.json()

        if (response.ok) {
            navigate('/Profile');
        }

        if (response.status === 404) {
            navigate("/404Error")
        }

        if (response.status === 401) {
            if (json.error === "Token Expired") {
                logout()
                navigate('/Login')
            }
            else {
                navigate("/401Error")
            }
        }
    }
    const handleAddJob = async (e) => {

        if (!user) {
            setError("Must Be Logged In")
            return null
        }

        //Check if job name already exists
        let temp = false

        task.jobs.find((j) => {
            if (j.jobName === jobName) {
                temp = true
                return null
            }
            return null
        })

        if (temp) {
            setError("Name has already been used")
            return null
        }

        // NEED TO CHECK IF INPUTS ARE EMPTY !!!!!
        if (!jobName || !variableInterval || !lastPreformedDate || !monthTimeInterval || !jobType || !lastPreformedVariable) {
            setError("Not All Fields have been filled in")
            return null
        }

        let expectedPreformDate = null
        //Add months for expected if interval is > 0

        if (monthTimeInterval > 0) {
            let temp = new Date(lastPreformedDate)
            expectedPreformDate = new Date(temp.getTime() + (monthTimeInterval * 2629800000)) // this 
        }

        //const jobElements = { jobName, variableInterval, lastPreformedDate, monthTimeInterval: null, expectedPreformDate, lastPreformedVariable: null, jobType, repairReason: null, needMaintenance: null }
        const job = { jobs: { jobName, variableInterval, lastPreformedDate, monthTimeInterval, expectedPreformDate, lastPreformedVariable, jobType, repairReason: null, needMaintenance: null } }

        calcMaintenance(job.jobs, task.trackingVariableValue, task.lastUpdated)

        //Need to Change fetch location
        const response = await fetch(process.env.REACT_APP_BACKENDURL + '/api/addjobs/' + task._id, {
            method: 'PATCH',
            body: JSON.stringify(job),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }

        })

        const json = await response.json()

        if (!response.ok) {
            setError(json.error)

            if (response.status === 404) {

                navigate("/404Error")
            }

            if (response.status === 401) {

                if (json.error === "Token Expired") {
                    logout()
                    navigate('/Login')
                }
                else {
                    navigate("/401Error")
                }
            }

        }
        if (response.ok) {

            //dispatch({ type: "ADD_JOB", payload: jobElements })
            handleCloseAddJob()
            //console.log(task)
            //THis is not ideal at all but is only workaround at the moment. context doesnt have _id so remaping fails
            setError("")

            //Refresh
            navigate(0)

        }

    }
    const handleDeleteJob = async (e) => {

        if (!user) {
            setError("Must Be Logged In")
            return null
        }

        if (!selected) {
            return
        }

        const job = { jobName: selected }

        const response = await fetch(process.env.REACT_APP_BACKENDURL + '/api/deletejobs/' + task._id, {
            method: "PATCH",
            body: JSON.stringify(job),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`

            }
        })

        const json = await response.json()

        if (!response.ok) {
            if (response.status === 404) {
                navigate("/404Error")
            }

            if (response.status === 401) {

                if (json.error === "Token Expired") {
                    logout()
                    navigate('/Login')
                }
                else {
                    navigate("/401Error")
                }
            }
        }
        if (response.ok) {
            //THis is not ideal at all but is only workaround at the moment. context doesnt have _id so remaping fails
            //Refresh
            navigate(0)

        }

    }
    function UpdateJobs() {

        // ensure the update cannot be smaller then current!!!!

        if (!user) {
            setError("Must Be Logged In")
            return null
        }

        if (task.trackingVariableValue > newVariable) {
            setError("That Value was invalid")
            return
        }

        let updatedDate = new Date()

        for (const job of task.jobs) {
            calcMaintenance(job, newVariable, updatedDate)
        }

        // Update Task with new date and variable
        task.lastUpdated = updatedDate
        task.trackingVariableValue = newVariable


        // Update database with new values
        updateTask()

        handleCloseUpdateTask()
        setError("")
    }
    async function updateTask() {

        if (!user) {
            setError("Must Be Logged In")
            return null
        }

        //Need to Change fetch location
        const response = await fetch(process.env.REACT_APP_BACKENDURL + '/api/tasks/' + task._id, {
            method: 'PATCH',
            body: JSON.stringify(task),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`

            }
        })

        const json = await response.json()

        if (!response.ok) {
            setError(json.error)

            if (response.status === 404) {

                navigate("/404Error")
            }

            if (response.status === 401) {

                if (json.error === "Token Expired") {
                    logout()
                    navigate('/Login')
                }
                else {
                    navigate("/401Error")
                }
            }
        }
        if (response.ok) {

            //dispatch({ type: "ADD_JOB", payload: jobElements })        
            //THis is not ideal at all but is only workaround at the moment. context doesnt have _id so remaping fails
            //Refresh
            navigate(0)

        }
    }
    function calcMaintenance(job, newVariable, updatedDate) {

        //Variable until need maintenance

        if ((job.variableInterval - (newVariable - job.lastPreformedVariable) <= 0 && job.variableInterval !== 0)) {

            if (job.repairReason === "MILES & TIME") {

            }

            else if (job.repairReason === "TIME") {
                job.repairReason = "MILES & TIME"
            }
            else {
                job.repairReason = "MILES"
                job.needMaintenance = true
            }
        }

        else {

            if (job.repairReason === "MILES & TIME") {
                job.repairReason = "TIME"
                job.needMaintenance = true
            }
            else if (job.repairReason === "MILES") {
                job.repairReason = ""
                job.needMaintenance = false
            }
        }


        //If month time interval is not required
        if (job.monthTimeInterval === 0) {
            return
        }

        //calendar time until need maintennance
        let expect = new Date(job.expectedPreformDate)
        let update = new Date(updatedDate)

        //console.log(update > expect)

        if (update >= expect) {
            if (job.repairReason === "MILES & TIME") {

            }

            else if (job.repairReason === "MILES") {
                job.repairReason = "MILES & TIME"
            }

            else {
                job.repairReason = "TIME"
                job.needMaintenance = true
            }
        }

        else {

            if (job.repairReason === "MILES & TIME") {
                job.repairReason = "MILES"
                job.needMaintenance = true
            }
            else if (job.repairReason === "TIME") {
                job.repairReason = ""
                job.needMaintenance = false
            }

        }
    }

    function createTrackerPDF() {

        if (!user) {
            return null
        }

        const doc = new jsPDF('landscape')

        doc.text(task.taskName + " Tracking Report", 15, 10)
        doc.text("Current " + task.trackingVariableName + ": " + task.trackingVariableValue + "    Date: " + convertDate(task.lastUpdated), 180, 10)
        doc.text("Created Using:  WWW.DIYTASKTRACKER.COM", 160, 200)

        doc.autoTable({
            columns: [
                { header: 'Job Name', dataKey: 'jobName' },
                { header: task.trackingVariableName + ' Interval', dataKey: 'variableInterval' },
                { header: 'Last Date', dataKey: 'lastPreformedDate' },
                { header: 'Expected Date', dataKey: 'expectedPreformDate' },
                { header: 'Last ' + task.trackingVariableName, dataKey: 'lastPreformedVariable' },
                { header: 'Expected ' + task.trackingVariableName, dataKey: 'expectedtracking' },
                { header: 'Job Type', dataKey: 'jobType' },
                { header: 'Repair Reason', dataKey: 'repairReason' },
                { header: 'Need Maintenance', dataKey: 'needMaintenance' },],

            body: task.jobs.map(({ jobName, variableInterval, lastPreformedDate, expectedPreformDate, lastPreformedVariable, jobType, repairReason, needMaintenance }) => {
                return [jobName, variableInterval, convertDate(lastPreformedDate), convertDate(expectedPreformDate), lastPreformedVariable, lastPreformedVariable + variableInterval, jobType, repairReason, needMaintenance]
            }),

            headStyles: {
                fillColor: [0, 0, 255],
                fontSize: 13,
            },

            didParseCell: function (data) {
                if (data.cell.text[0] === 'true') {
                    for (const x in data.row.cells) {
                        data.row.cells[x].styles.textColor = [255, 0, 0]
                    }
                }
            }
        })

        doc.save(task.taskName + " Tracking PDF")

    }

    function CompleteTrackedJob() {

        if (!user) {
            setError("Must Be Logged In")
            return null
        }

        if (!selected) {
            return
        }

        //Create Completed Job based on old job
        let jobName = selected
        let completedVariableValue = task.trackingVariableValue
        let completedDate = task.lastUpdated
        const customJob = { completedJobs: { jobName, completedDate, completedVariableValue, replacementProductName, replacementProductLink, completedJobDescription } }

        //Add New Completed Job To completed Array

        task.completedJobs.push(customJob.completedJobs)

        //Recalculate Job Maintenance

        //find job based off of selected

        for (let i = 0; i < task.jobs.length; i++) {
            if (task.jobs[i].jobName === selected) {
                task.jobs[i].lastPreformedVariable = completedVariableValue
                task.jobs[i].lastPreformedDate = completedDate
                let temp = new Date(completedDate)
                task.jobs[i].expectedPreformDate = new Date(temp.getTime() + (task.jobs[i].monthTimeInterval * 2629800000))

                calcMaintenance(task.jobs[i], completedVariableValue, completedDate)
                break
            }
        }
        //Calc new values for updated job

        console.log(task.jobs)


        //push New Info to DB
        setError("")
        handleCloseAddCustomJob()
        updateTask()


    }

    return (
        <>
            {/* THis is the Update Modal*/}
            <Modal
                open={openUpdateTask}
                onClose={handleCloseUpdateTask}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box id="ModalCard" sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '70%', lg: '1000px' },
                    bgcolor: 'background.paper',
                    borderRadius: '25px',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ fontSize: { xs: '25px', lg: '35px' } }}>
                        Update {task.taskName}
                    </Typography>

                    <TextField id="outlined-basic" label={"New " + task.trackingVariableName + " Amount"} variant="outlined" type="number" size='small' sx={{
                        marginLeft: { md: 1, lg: 20 },
                        marginRight: { md: 1, lg: 20 },
                        marginTop: 3,
                        marginBottom: 1,

                    }}
                        value={newVariable}
                        onChange={(e) => {
                            setNewVariable(e.target.value);
                        }}
                    />

                    <Typography sx={{ color: 'red' }}>{error}</Typography>

                    <Box sx={{

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: 0,
                        marginTop: 2


                    }}>
                        <Button variant="outlined" size="medium" sx={{
                            borderRadius: 25,
                            border: 3,
                            ":hover": {
                                border: 3,
                            },
                            borderColor: "primary.dark",
                            color: "primary.light",
                            fontFamily: 'Allerta Stencil, sans-serif',
                        }}
                            onClick={handleCloseUpdateTask}
                        >Cancel</Button>

                        <Button variant="contained" size="large" sx={{
                            borderRadius: 25,
                            backgroundImage: 'linear-gradient(180deg, rgba(5, 0, 255, 1) 0%, rgba(0, 255, 56, 1) 100%)',
                            fontFamily: 'Allerta Stencil, sans-serif',
                            marginLeft: 17,
                        }}
                            onClick={UpdateJobs}
                        >Submit</Button>
                    </Box>
                </Box>

            </Modal >

            {/*THis is the Add Custom Job Modal for Tracker*/}
            <Modal
                open={openAddCustomJob}
                onClose={handleCloseAddCustomJob}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box id="" sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '70%', lg: '1000px' },
                    bgcolor: 'background.paper',
                    borderStyle: "solid",
                    borderColor: "#00ff38",
                    borderWidth: 5,
                    borderRadius: '25px',
                    boxShadow: 24,
                    textAlign: 'center',
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ fontSize: { xs: '25px', lg: '35px' } }}>
                        Add Custom Job
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <TextField id="outlined-basic" label="Job Name" variant="outlined" disabled={true} size='small' sx={{
                            marginLeft: { xs: 1, lg: 20 },
                            marginRight: { xs: 1, lg: 20 },
                            marginTop: 1,
                            marginBottom: 1,

                        }}
                            value={selected}
                        />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker sx={{
                                marginLeft: { xs: 1, lg: 20 },
                                marginRight: { xs: 1, lg: 20 },
                                marginTop: 1,
                                marginBottom: 1

                            }}

                                defaultValue={dayjs(task.lastUpdated)}
                                label="Date" disabled
                                // onChange={(e) => {
                                //     setcompletedDate(e)
                                // }}
                                slotProps={{ textField: { size: 'small' } }}
                            ></DatePicker>
                        </LocalizationProvider>

                        <TextField id="outlined-basic" label={task.trackingVariableName + " When Job Was Completed"} variant="outlined" disabled={true} size="small" sx={{
                            marginLeft: { xs: 1, lg: 20 },
                            marginRight: { xs: 1, lg: 20 },
                            marginTop: 1,
                            marginBottom: 1,

                        }}
                            value={task.trackingVariableValue}
                        // onChange={(e) => {
                        //     setcompletedVariableValue(e.target.value);
                        // }}
                        />

                        <TextField id="outlined-basic" label={"Product Name (Optional)"} variant="outlined" size='small' sx={{
                            marginLeft: { xs: 1, lg: 20 },
                            marginRight: { xs: 1, lg: 20 },
                            marginTop: 1,
                            marginBottom: 1,

                        }}
                            value={replacementProductName}
                            onChange={(e) => {
                                setreplacementProductName(e.target.value);
                            }}
                        />


                        <TextField id="outlined-basic" label="Product Link (Optional)" variant="outlined" size='small' sx={{
                            marginLeft: { xs: 1, lg: 20 },
                            marginRight: { xs: 1, lg: 20 },
                            marginTop: 1,
                            marginBottom: 1,

                        }}
                            value={replacementProductLink}
                            onChange={(e) => {
                                setreplacementProductLink(e.target.value);
                            }}
                        />

                        <TextField id="outlined-basic" label="Job Description (Optional)" variant="outlined" size='small' sx={{
                            marginLeft: { xs: 1, lg: 20 },
                            marginRight: { xs: 1, lg: 20 },
                            marginTop: 1,
                            marginBottom: 1,

                        }}
                            multiline
                            value={completedJobDescription}
                            onChange={(e) => {
                                setcompletedJobDescription(e.target.value);
                            }}
                        />

                        <Typography sx={{ color: 'red' }}>{error}</Typography>

                    </Box>
                    <Box sx={{

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: 0,
                        marginTop: 2


                    }}>
                        <Button variant="outlined" size="medium" sx={{
                            borderRadius: 25,
                            border: 3,
                            ":hover": {
                                border: 3,
                            },
                            borderColor: "primary.dark",
                            color: "primary.light",
                            fontFamily: 'Allerta Stencil, sans-serif',
                        }}
                            onClick={handleCloseAddCustomJob}
                        >Cancel</Button>

                        <Button variant="contained" size="large" sx={{
                            borderRadius: 25,
                            backgroundImage: 'linear-gradient(180deg, rgba(5, 0, 255, 1) 0%, rgba(0, 255, 56, 1) 100%)',
                            fontFamily: 'Allerta Stencil, sans-serif',
                            marginLeft: 17,
                        }}
                            onClick={CompleteTrackedJob}
                        >Submit</Button>


                    </Box>

                </Box>

            </Modal >

            {/* this is the Delete job Modal */}
            <Modal
                open={openDeleteJob}
                onClose={handleCloseDeleteJob}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box id="" sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '70%', lg: '1000px' },
                    bgcolor: 'background.paper',
                    borderStyle: "solid",
                    borderColor: "Red",
                    borderWidth: 5,
                    borderRadius: '25px',
                    boxShadow: 24,
                    textAlign: 'center',
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ fontSize: { xs: '25px', lg: '35px' } }}>
                        Delete Job
                    </Typography>
                    <Typography variant="h5" sx={{ margin: { xs: 2, lg: 5 }, fontSize: { xs: '20px', lg: '35px' } }}> Are you sure you want to delete job: {selected}??</Typography>

                    <Box sx={{

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: 0,
                        marginTop: 3


                    }}>
                        <Button variant="outlined" size="medium" sx={{
                            borderRadius: 25,
                            border: 3,
                            ":hover": {
                                border: 3,
                            },
                            borderColor: "primary.dark",
                            color: "primary.light",
                            fontFamily: 'Allerta Stencil, sans-serif',
                        }}
                            onClick={handleCloseDeleteJob}
                        >Cancel</Button>

                        <Button variant="contained" size="large" sx={{
                            borderRadius: 25,
                            backgroundImage: 'linear-gradient(180deg, rgba(5, 0, 255, 1) 0%, rgba(0, 255, 56, 1) 100%)',
                            fontFamily: 'Allerta Stencil, sans-serif',
                            marginLeft: 17,
                        }}
                            onClick={handleDeleteJob}
                        >Submit</Button>


                    </Box>

                </Box>

            </Modal >
            {/*THis is the Delete Modal for Tracker*/}
            <Modal
                open={openDeleteTracker}
                onClose={handleCloseDeleteTracker}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box id="" sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '70%', lg: '1000px' },
                    bgcolor: 'background.paper',
                    borderStyle: "solid",
                    borderColor: "Red",
                    borderWidth: 5,
                    borderRadius: '25px',
                    boxShadow: 24,
                    textAlign: 'center',
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h4" component="h2">
                        Delete Tracker
                    </Typography>
                    <Typography variant="h5" sx={{ margin: 5 }}> Are you sure you want to delete tracker: {task.taskName}??</Typography>

                    <Box sx={{

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: 2,
                        marginTop: 4


                    }}>
                        <Button variant="outlined" size="medium" sx={{
                            borderRadius: 25,
                            border: 3,
                            ":hover": {
                                border: 3,
                            },
                            borderColor: "primary.dark",
                            color: "primary.light",
                            fontFamily: 'Allerta Stencil, sans-serif',
                        }}
                            onClick={handleCloseDeleteTracker}
                        >Cancel</Button>

                        <Button variant="contained" size="large" sx={{
                            borderRadius: 25,
                            backgroundImage: 'linear-gradient(180deg, rgba(5, 0, 255, 1) 0%, rgba(0, 255, 56, 1) 100%)',
                            fontFamily: 'Allerta Stencil, sans-serif',
                            marginLeft: 17,
                        }}
                            onClick={handleDeleteTracker}
                        >Submit</Button>


                    </Box>

                </Box>

            </Modal >
            {/*THis is the Add Job Modal for Tracker*/}
            <Modal
                open={openAddJob}
                onClose={handleCloseAddJob}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box id="" sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '70%', lg: '1000px' },
                    bgcolor: 'background.paper',
                    borderStyle: "solid",
                    borderColor: "Blue",
                    borderWidth: 5,
                    borderRadius: '25px',
                    boxShadow: 24,
                    textAlign: 'center',
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ fontSize: { xs: '25px', lg: '35px' } }}>
                        Add Tracked Job
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <TextField id="outlined-basic" label="JobName" variant="outlined" size="small" sx={{
                            marginLeft: { xs: 1, lg: 20 },
                            marginRight: { xs: 1, lg: 20 },
                            marginTop: 1,
                            marginBottom: 1,

                        }}
                            value={jobName}
                            onChange={(e) => {
                                setJobName(e.target.value);
                            }}
                        />

                        <FormLabel>Last Preformed Job</FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker sx={{
                                marginLeft: { xs: 1, lg: 20 },
                                marginRight: { xs: 1, lg: 20 },
                                marginTop: 1,
                                marginBottom: 1

                            }}

                                value={lastPreformedDate}
                                onChange={(e) => {
                                    setLastPreformedDate(e)
                                }}
                                slotProps={{ textField: { size: 'small' } }}
                            ></DatePicker>
                        </LocalizationProvider>

                        <TextField id="outlined-basic" label={task.trackingVariableName + " When Job was Completed"} variant="outlined" size="small" sx={{
                            marginLeft: { xs: 1, lg: 20 },
                            marginRight: { xs: 1, lg: 20 },
                            marginTop: 1,
                            marginBottom: 1,

                        }}
                            type='number'
                            value={lastPreformedVariable}
                            onChange={(e) => {
                                setLastPreformedVariable(e.target.value);
                            }}
                        />

                        <TextField id="outlined-basic" label={task.trackingVariableName + " Interval"} variant="outlined" size="small" sx={{
                            marginLeft: { xs: 1, lg: 20 },
                            marginRight: { xs: 1, lg: 20 },
                            marginTop: 1,
                            marginBottom: 1,

                        }}
                            type='number'
                            value={variableInterval}
                            onChange={(e) => {
                                setVariableInterval(e.target.value);
                            }}
                        />


                        <TextField id="outlined-basic" label="Month Interval" variant="outlined" size="small" sx={{
                            marginLeft: { xs: 1, lg: 20 },
                            marginRight: { xs: 1, lg: 20 },
                            marginTop: 1,
                            marginBottom: 1,

                        }}
                            type='number'
                            value={monthTimeInterval}
                            onChange={(e) => {
                                setMonthTimeInterval(e.target.value);
                            }}
                        />

                        <TextField id="outlined-basic" label="Job Type (Max length 10)" variant="outlined" size="small" sx={{
                            marginLeft: { xs: 1, lg: 20 },
                            marginRight: { xs: 1, lg: 20 },
                            marginTop: 1,
                            marginBottom: 1,

                        }}
                            inputProps={{ maxLength: 10 }}
                            value={jobType}
                            onChange={(e) => {
                                setJobType(e.target.value.toUpperCase());
                            }}
                        />

                        <Typography sx={{ color: 'red' }}>{error}</Typography>

                    </Box>
                    <Box sx={{

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: 0,
                        marginTop: 2


                    }}>
                        <Button variant="outlined" size="medium" sx={{
                            borderRadius: 25,
                            border: 3,
                            ":hover": {
                                border: 3,
                            },
                            borderColor: "primary.dark",
                            color: "primary.light",
                            fontFamily: 'Allerta Stencil, sans-serif',
                        }}
                            onClick={handleCloseAddJob}
                        >Cancel</Button>

                        <Button variant="contained" size="large" sx={{
                            borderRadius: 25,
                            backgroundImage: 'linear-gradient(180deg, rgba(5, 0, 255, 1) 0%, rgba(0, 255, 56, 1) 100%)',
                            fontFamily: 'Allerta Stencil, sans-serif',
                            marginLeft: 17,
                        }}
                            onClick={handleAddJob}
                        >Submit</Button>


                    </Box>

                </Box>

            </Modal >
            <Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", alignItems: 'flex-start', marginTop: 5, marginBottom: 4 }}>
                <ArrowBackIcon fontSize="large" sx={{
                    cursor: 'pointer', "&:hover": { backgroundColor: '#d1d1d1', borderRadius: "25px" },
                }} onClick={(event) => { navigate("/Profile") }}>
                </ArrowBackIcon>

                <Typography variant="h3">{task.taskName}</Typography>

                <DeleteForeverOutlinedIcon fontSize="large" sx={{
                    cursor: 'pointer', "&:hover": { backgroundColor: '#d1d1d1', borderRadius: "25px" },
                }} onClick={handleOpenDeleteTracker}>
                </DeleteForeverOutlinedIcon>
            </Box>

            <Box sx={{ borderColor: "#0500ff", borderStyle: "solid", borderRadius: "25px", borderWidth: "5px", marginTop: '15px', marginLeft: '15px', marginRight: '15px', padding: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h4" >Tracked Jobs</Typography>
                </Box>
                {/*THis is  the left column */}

                <Box className='Trackersection'
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        flexWrap: "wrap",
                        flexDirection: { md: 'column', xl: 'row' }
                    }}
                >

                    <Box sx={{
                        textAlign: "center",
                        margin: 2
                    }}>
                        <Typography variant="h5" sx={{ margin: 2 }}>Last Updated Date:<br></br><Typography sx={{ color: "#00ff38", fontSize: 28 }}>{convertDate(task.lastUpdated)}</Typography></Typography>

                        <Typography variant="h5" sx={{ margin: 2 }}>Tracking Variable:<br></br><Typography sx={{ color: "#00ff38", fontSize: 28 }}>{task.trackingVariableName}: {task.trackingVariableValue}</Typography></Typography>

                        <Box sx={{ display: 'flex', flexDirection: "column" }}>

                            <Button variant="contained" size="large" sx={{
                                borderRadius: 25,
                                backgroundImage: 'linear-gradient(180deg, rgba(5, 0, 255, 1) 0%, rgba(0, 255, 56, 1) 100%)',
                                fontFamily: 'Allerta Stencil, sans-serif',
                                margin: 2
                            }} onClick={handleOpenUpdateTask}>
                                Update</Button>

                            <Button variant="outlined" size="meduim" sx={{
                                borderRadius: 25,
                                border: 3,
                                ":hover": {
                                    border: 3,
                                },
                                borderColor: "black",
                                color: "black",
                                fontFamily: 'Allerta Stencil, sans-serif',
                                margin: 2
                            }} onClick={createTrackerPDF}>Download PDF</Button>

                        </Box>
                    </Box>

                    {/*THis is the Table */}
                    <Box sx={{ display: 'flex', flexDirection: "column", width: { xs: '90%', xl: 1175 } }}>
                        <TableContainer component={Paper}>
                            <Table stickyHeader style={{ width: 'max-content', overflow: "auto" }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Job Name</TableCell>
                                        <TableCell>{task.trackingVariableName} Interval</TableCell>
                                        <TableCell>Last Date</TableCell>
                                        <TableCell>Expected Date</TableCell>
                                        <TableCell>Last {task.trackingVariableName}</TableCell>
                                        <TableCell>Expected {task.trackingVariableName}</TableCell>
                                        <TableCell>Job Type</TableCell>
                                        <TableCell>Repair Reason</TableCell>
                                        <TableCell>Need Maintenance</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        task.jobs.map(row => (
                                            <TableRow key={row.jobName} id={row.jobName} sx={{ '&:last-child td, &last-child th': { border: 0 }, cursor: 'pointer', backgroundColor: "white", color: row.needMaintenance === true ? 'red' : 'black' }} hover onClick={handleSelect}>
                                                <TableCell align='center' sx={{ color: 'inherit' }}>{row.jobName}</TableCell>
                                                <TableCell align='center' sx={{ color: 'inherit' }}>{row.variableInterval}</TableCell>
                                                <TableCell align='center' sx={{ color: 'inherit' }}>{convertDate(row.lastPreformedDate)}</TableCell>
                                                <TableCell align='center' sx={{ color: 'inherit' }}>{convertDate(row.expectedPreformDate)}</TableCell>
                                                <TableCell align='center' sx={{ color: 'inherit' }}>{row.lastPreformedVariable}</TableCell>
                                                <TableCell align='center' sx={{ color: 'inherit' }}>{row.lastPreformedVariable + row.variableInterval}</TableCell>
                                                <TableCell align='center' sx={{ color: 'inherit' }}>{row.jobType}</TableCell>
                                                <TableCell align='center' sx={{ color: 'inherit' }}>{row.repairReason}</TableCell>
                                                <TableCell align='center' sx={{ color: 'inherit' }}>{convertBoolean(row.needMaintenance)}</TableCell>
                                            </TableRow>
                                        ))
                                    }

                                </TableBody>

                            </Table>
                        </TableContainer>
                    </Box>


                    {/*THis is the right column */}
                    <Box sx={{ textAlign: "center", margin: 2 }}>

                        <Box sx={{ display: 'flex', flexDirection: "column" }}>

                            <Button variant="outlined" size="meduim" sx={{
                                borderRadius: 25,
                                border: 3,
                                ":hover": {
                                    border: 3,
                                },
                                borderColor: "Blue",
                                color: "Blue",
                                fontFamily: 'Allerta, sans-serif',
                                margin: 2
                            }}
                                onClick={handleOpenAddJob}
                            >Add Tracked Job</Button>

                            <Button variant="outlined" size="meduim" sx={{
                                borderRadius: 25,
                                border: 3,
                                ":hover": {
                                    border: 3,
                                },
                                borderColor: "Red",
                                color: "Red",
                                fontFamily: 'Allerta, sans-serif',
                                margin: 2,
                            }}
                                onClick={(event) => { if (selected != null) handleOpenDeletejob() }}
                            >Delete Tracker</Button>

                            <Button variant="outlined" size="meduim" sx={{
                                borderRadius: 25,
                                border: 3,
                                ":hover": {
                                    border: 3,
                                },
                                borderColor: "Green",
                                color: "Green",
                                fontFamily: 'Allerta, sans-serif',
                                margin: 2
                            }} onClick={(event) => { if (selected != null) handleOpenAddCustomJob() }}>Complete Tracked Job</Button>

                        </Box>
                    </Box>

                </Box>

            </Box >


        </>
    )
}

export default Tracker